import React from "react"
import {Link} from 'gatsby'

const ffmpegBase='ffmpeg-all-codecs'
const ffmpeg=ffmpegBase+'.'+'js'

export default () => (
  <>
    <div>cdn</div>
    <Link to={`/${ffmpeg}`}>{ffmpeg}</Link>
  </>
)
